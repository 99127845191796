@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans text-gray-900 leading-relaxed;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

/* ACCESSIBILITY */

.accessibility-contrast {
  background-color: #000 !important;
  color: #fff !important;
}

html.accessibility-readable-font,
html.accessibility-readable-font * {
  font-family: 'Arial', 'Open Sans', sans-serif !important;
}


.accessibility-underline-links a {
  text-decoration: underline !important;
}

.accessibility-reduce-motion * {
  animation: none !important;
  transition: none !important;
  scroll-behavior: auto !important;
}

/* Global high-contrast mode (WCAG 2.0 AA / תקן 5568) */
.contrast-mode * {
  background: none !important;
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  text-shadow: none !important;
  border-color: #000 !important;
  outline-color: #000 !important;
}

/* Links should remain obvious */
.contrast-mode a {
  text-decoration: underline !important;
  color: #0000EE !important; /* classic accessible link color */
}

/* Inputs and buttons should still be usable */
.contrast-mode button,
.contrast-mode input,
.contrast-mode select,
.contrast-mode textarea {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  box-shadow: none !important;
}

/* Remove background images (safe for decorative only) */
.contrast-mode img,
.contrast-mode video {
  filter: grayscale(1) contrast(1.2);
  opacity: 1;
}

/* OPTIONAL: hide purely decorative images */
.contrast-mode .decorative,
.contrast-mode [aria-hidden=\"true\"] {
  display: none !important;
}


/* SCROLL BAR */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track { /* Track */
  background: lightgray;
}

::-webkit-scrollbar-thumb { /* Handle */
  background: #6A1B9A;
}

::-webkit-scrollbar-thumb:hover { /* Handle on hover */
  background: #555;
}